<nb-layout>
  <nb-layout-column>
    <div class="login-container">
      <nb-card>
        <nb-card-body>

          <h1 class="title" id="title">Tongs Access</h1>
          <h6 class="sub-title">Silahkan Login</h6>

          <!--    <nb-alert *ngIf="showMessages.error && errors?.length && !submitted" outline="danger" role="alert">-->
          <!--      <p class="alert-title"><b>Oh snap!</b></p>-->
          <!--      <ul class="alert-message-list">-->
          <!--        <li *ngFor="let error of errors" class="alert-message">{{ error }}</li>-->
          <!--      </ul>-->
          <!--    </nb-alert>-->

          <!--    <nb-alert *ngIf="showMessages.success && messages?.length && !submitted" outline="success" role="alert">-->
          <!--      <p class="alert-title"><b>Hooray!</b></p>-->
          <!--      <ul class="alert-message-list">-->
          <!--        <li *ngFor="let message of messages" class="alert-message">{{ message }}</li>-->
          <!--      </ul>-->
          <!--    </nb-alert>-->


          <div class="form-control-group">
            <label class="label" for="input-email">Email:</label>
            <input [(ngModel)]="userId"
                   autofocus
                   fieldSize="large"
                   fullWidth
                   id="input-email"
                   name="email"
                   nbInput
                   pattern=".+@.+\..+"
                   (keyup.enter)="onLogin()"
                   placeholder="Email address">
            <!--        <ng-container *ngIf="email.invalid && email.touched">-->
            <!--          <p class="caption status-danger" *ngIf="email.errors?.required">-->
            <!--            Email is required!-->
            <!--          </p>-->
            <!--          <p class="caption status-danger" *ngIf="email.errors?.pattern">-->
            <!--            Email should be the real one!-->
            <!--          </p>-->
            <!--        </ng-container>-->
          </div>
          <div class="form-control-group">
          <span class="label-with-link">
            <label class="label" for="input-password">Password:</label>
          </span>
            <input [(ngModel)]="password"
                   fieldSize="large"
                   fullWidth
                   id="input-password"
                   name="password"
                   nbInput
                   placeholder="Password"
                   type="password"
                   (keyup.enter)="onLogin()"
            >
            <!--      <ng-container *ngIf="password.invalid && password.touched ">-->
            <!--        <p class="caption status-danger" *ngIf="password.errors?.required">-->
            <!--          Password is required!-->
            <!--        </p>-->
            <!--        <p class="caption status-danger" *ngIf="password.errors?.minlength || password.errors?.maxlength">-->
            <!--          Password should contain-->
            <!--          from {{ getConfigValue('forms.validation.password.minLength') }}-->
            <!--          to {{ getConfigValue('forms.validation.password.maxLength') }}-->
            <!--          characters-->
            <!--        </p>-->
            <!--      </ng-container>-->
          </div>

          <!--      <div class="form-control-group accept-group">-->
          <!--        <nb-checkbox name="rememberMe" [(ngModel)]="user.rememberMe" *ngIf="rememberMe">Remember me</nb-checkbox>-->
          <!--      </div>-->

          <button fullWidth
                  nbButton
                  size="large"
                  status="primary"
                  [nbSpinner]="loading"
                  (click)="onLogin()">
            Login
          </button>
          <br />
          <br />
        </nb-card-body>
      </nb-card>
    </div>
  </nb-layout-column>
</nb-layout>

import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';
import {environment} from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CryptoService {

  constructor() { }

  encryptString(str: string): string | null {
    try {
      return CryptoJS.AES.encrypt(str, environment.secretKey).toString();
    } catch (e) {
      console.log(e);
      return null;
    }
  }

  decryptString(str: string): string | null {
    try {
      const bytes = CryptoJS.AES.decrypt(str, environment.secretKey);
      if (bytes.toString()) {
        return bytes.toString(CryptoJS.enc.Utf8);
      }
      return str;
    } catch (e) {
      console.log(e);
      return null;
    }
  }
}

import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable} from 'rxjs';
import {FileDownloadContentType} from '../constants/file-download-content-type';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpClientWrapperService {

  constructor(private http: HttpClient) {
  }

  static createContentTypeHeader(headers: HttpHeaders | null = null): HttpHeaders {
    if (headers === null) {
      headers = new HttpHeaders();
    }

    headers = headers.set('Content-Type', 'application/json; charset=utf-8');
    return headers;
  }

  static createDefaultHeader(headers: HttpHeaders | null = null): HttpHeaders {
    if (headers === null) {
      headers = new HttpHeaders();
    }

    headers = HttpClientWrapperService.createContentTypeHeader(headers);

    return headers;
  }

  get<T>(url: string, params: any = null, headers: HttpHeaders | null = null, responseType: any = 'json'): Observable<T> {
    if (headers === null) {
      headers = HttpClientWrapperService.createDefaultHeader();
    }
    return this.http.get<T>(url, {headers, params, responseType});
  }

  downloadBlob(url: string, params: any = null, fileContentType: FileDownloadContentType = FileDownloadContentType.XLS): Observable<Blob> {
    const headers = new HttpHeaders();
    headers.set('Content-Type', 'application/octet-stream');
    return this.http.get(url, {headers, params, responseType: 'blob'})
      .pipe(
        map((res: any) => {
          return new Blob([res], {type: fileContentType});
        })
      );
  }

  post<T>(url: string, payload: any = null, headers: HttpHeaders | null = null): Observable<T> {
    if (headers === null) {
      headers = HttpClientWrapperService.createDefaultHeader();
    }
    return this.http.post<T>(url, payload, {headers});
  }

  put<T>(url: string, payload: any = null): Observable<T> {
    const headers = HttpClientWrapperService.createDefaultHeader();
    return this.http.put<T>(url, payload, {headers});
  }

  patch<T>(url: string, payload: any = null): Observable<T> {
    const headers = HttpClientWrapperService.createDefaultHeader();
    return this.http.patch<T>(url, payload, {headers});
  }

  delete<T>(url: string, params: any = null): Observable<T> {
    const headers = HttpClientWrapperService.createDefaultHeader();
    return this.http.delete<T>(url, {headers, params});
  }

  addEmptyFormDataHeader(headers: HttpHeaders | null = null): HttpHeaders {
    if (headers == null) {
      headers = new HttpHeaders();
    }

    return headers;
  }
}

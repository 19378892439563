import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {NbToastrService} from '@nebular/theme';
import {LocalStorageKey} from '../constants/local-storage-key';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

  constructor(private toastr: NbToastrService, private router: Router) {
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = localStorage.getItem(LocalStorageKey.TOKEN);
    if (token) {
      request = request.clone({
        headers: request.headers.set('Authorization',
          'Bearer ' + token)
      });
    }

    return next.handle(request).pipe(tap(() => {},
      (err: any) => {
        if (err instanceof HttpErrorResponse) {
          if (err.status !== 401) {
            return;
          }
          this.toastr.warning('Please login first', 'Unauthenticated');
          // force logout by removing local storage
          localStorage.removeItem(LocalStorageKey.LOGGED_IN_USER);
          localStorage.removeItem(LocalStorageKey.TOKEN);
          localStorage.removeItem(LocalStorageKey.EXPIRES_AT);
          this.router.navigate(['/login']);
        }
      }));
  }
}

import {Component, OnInit} from '@angular/core';
import {AuthService} from '../core/services/auth.service';
import {NbToastrService} from '@nebular/theme';
import {Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  userId = '';
  password = '';
  loading = false;

  constructor(private authService: AuthService,
              private toastr: NbToastrService,
              private router: Router) {
  }

  ngOnInit(): void {
  }

  onLogin(): void {
    if (this.userId.length === 0) {
      this.toastr.danger('Silahkan isi Email!', 'Login Error');
      return;
    }
    if (this.password.length === 0) {
      this.toastr.danger('Silahkan isi Password!', 'Login Error');
      return;
    }
    this.loading = true;
    this.authService.login(this.userId, this.password)
      .subscribe(resp => {
        console.log(resp);
        this.loading = false;
        if (resp.success) {
          this.router.navigate(['/pages']);
        } else {
          this.toastr.danger(resp.errorMessage, 'Login Gagal');
        }
      }, error => {
        this.loading = false;
        this.toastr.danger(error.error.message, 'Login Gagal');
      });
  }

}

import {Component, Input, OnInit} from '@angular/core';
import {NbDialogRef} from '@nebular/theme';

@Component({
  selector: 'app-modal-delete-data',
  templateUrl: './modal-delete-data.component.html',
  styleUrls: ['./modal-delete-data.component.scss']
})
export class ModalDeleteDataComponent implements OnInit {
  @Input() name = '';

  constructor(protected ref: NbDialogRef<ModalDeleteDataComponent>) {
  }

  ngOnInit(): void {
  }

  onConfirm(): void {
    this.ref.close(true);
  }

  onDismiss(): void {
    this.ref.close(false);
  }
}

<nb-card class="modal-delete-data-container">
  <nb-card-header>Konfirmasi</nb-card-header>
  <nb-card-body>
    <h6>Apakah Anda yakin untuk menyetujui data ini {{name.length > 0 ? '[' + name + ']' : ''}}?</h6>
  </nb-card-body>
  <nb-card-footer>
    <div fxLayout="row" fxLayoutAlign="start start">
      <button nbButton hero status="success" class="modal-button" (click)="onConfirm()">
        Ya
      </button>
      <button nbButton hero status="basic" class="modal-button" (click)="onDismiss()">Tidak</button>
    </div>
  </nb-card-footer>
</nb-card>

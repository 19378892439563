import {Injectable} from '@angular/core';
import {CryptoService} from './crypto.service';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  constructor(private cryptoService: CryptoService) {
  }

  getByKey<T>(key: string): T | null {
    const encryptedStringData = localStorage.getItem(key);
    if (encryptedStringData !== null && encryptedStringData !== 'undefined') {
      const stringData = this.cryptoService.decryptString(encryptedStringData);
      if (stringData === null) { return null; }
      return JSON.parse(stringData);
    }
    return null;
  }

  setByKey<T>(key: string, data: T): void {
    const stringData = JSON.stringify(data);
    const encryptedStringData = this.cryptoService.encryptString(stringData);
    if (encryptedStringData !== null) {
      localStorage.setItem(key, encryptedStringData);
    }
  }

  removeByKey(key: string): void {
    localStorage.removeItem(key);
  }
}

import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {environment} from '../../../environments/environment';
import {HttpClientWrapperService} from '../utils/http-client-wrapper.service';
import {LocalStorageService} from '../utils/local-storage.service';
import {Observable, Subject} from 'rxjs';
import {SimpleApiResponse} from '../models/base/simple-api-response';
import {UserWithToken} from '../models/user-with-token';
import {BaseApiResponse} from '../models/base/base-api-response';
import {LocalStorageKey} from '../constants/local-storage-key';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  apiUrl = environment.baseApiUrl + 'auth';

  constructor(private http: HttpClientWrapperService,
              private localStorageService: LocalStorageService) {
  }

  login(username: string, password: string): Observable<SimpleApiResponse> {
    const url = this.apiUrl + '/login';
    const payload = {
      email: username,
      password
    };
    const simpleApiResponseSubject = new Subject<SimpleApiResponse>();
    simpleApiResponseSubject.next(new SimpleApiResponse());

    this.http.post<BaseApiResponse<UserWithToken>>(url, payload)
      .subscribe(result => {
        const user = result.data;
        user.expiredTs = new Date();
        user.expiredTs.setHours(new Date().getHours() + 12);
        this.localStorageService.setByKey(LocalStorageKey.LOGGED_IN_USER, user);

        // set token and expiry time
        const expiresAt = user.expiredTs;
        localStorage.setItem(LocalStorageKey.EXPIRES_AT, JSON.stringify(expiresAt.valueOf()));

        const simpleApiResponse = new SimpleApiResponse();
        simpleApiResponse.success = true;
        simpleApiResponseSubject.next(simpleApiResponse);
      }, error => {
        const simpleApiResponse = new SimpleApiResponse();
        simpleApiResponse.success = false;
        simpleApiResponse.errorMessage = error.error.message;
        simpleApiResponseSubject.next(simpleApiResponse);
      });

    return simpleApiResponseSubject.asObservable();
  }

  logout(): void {
    this.localStorageService.removeByKey(LocalStorageKey.LOGGED_IN_USER);
    this.localStorageService.removeByKey(LocalStorageKey.TOKEN);
    this.localStorageService.removeByKey(LocalStorageKey.EXPIRES_AT);
  }

  isLoggedIn(): boolean {
    return moment().isBefore(this.getExpiration());
  }

  getExpiration(): moment.Moment | null {
    const expiration = localStorage.getItem(LocalStorageKey.EXPIRES_AT);
    if (expiration != null) {
      const expiresAt = JSON.parse(expiration);
      return moment(expiresAt);
    }
    return null;
  }

  getCurrentUser(): UserWithToken | null {
    return this.localStorageService.getByKey<UserWithToken>(LocalStorageKey.LOGGED_IN_USER);
  }

  isAdmin(): boolean {
    const user = this.getCurrentUser();
    if (user !== null) {
      return user.levelId === 1;
    }
    return false;
  }
}

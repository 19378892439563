<nb-card class="modal-delete-data-container">
  <nb-card-header>Delete Data</nb-card-header>
  <nb-card-body>
    <h6>Apakah Anda yakin untuk menghapus data [{{name}}]?</h6>
  </nb-card-body>
  <nb-card-footer>
    <div fxLayout="row" fxLayoutAlign="start start">
      <button nbButton hero status="danger" class="modal-button" (click)="onConfirm()">
        Ya
      </button>
      <button nbButton hero status="primary" class="modal-button" (click)="onDismiss()">Tidak</button>
    </div>
  </nb-card-footer>
</nb-card>
